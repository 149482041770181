<template>
  <div id="container" :style="{ backgroundImage: 'url(' + require('../assets/pitch.jpg') + ')'}">
    <div class="jumbotron" style="background-color: transparent;padding:40px;margin: 0;">
      <h1 class="display-4" style="padding-top: 50px;">the match analyst</h1>
      <hr class="my-4">
      <p class="lead" style="padding-bottom: 50px;">
        I am a qualified football tactical analyst working in football analysis for the past 3 and a half years. Learning my trade with FC Barcelona 
        and working with Spanish youth teams and 'B' sides,  I now work in the lower levels of English football providing football analysis to several different clubs.
      </p>
    </div>
    
      <div class="jumbotron" style="background-color: #F2F2F2; color: #38404B">
        <div class="row">
            <div class="col-sm-4" style="padding:50px">
              <h1 class="display-4">Follow me</h1>
            </div>
            <div class="col-sm-6" style="padding:50px">
                I will be posting new videos on <a href="https://www.youtube.com/@the-match-analyst">YouTube</a> as often as I can with the primary focus being tactical analysis in the lower leagues of English football. 
                The lower leagues are sadly not well represented in the media so I plan to bring focus to those leagues offering the type of analysis I over to the 
                clubs that I report for.
            </div>
        </div>  
        <p class="lead">
        </p>
      </div>

      <div class="jumbotron">
      <div class="row">
          <div class="col-sm-6" style="padding: 50px;">
              Opposition and team analysis is increasingly more important in todays game. The ability to scrutinise opposition teams, providing strengths and
              weaknesses to the coaching staff can be the difference between winning and losing. I have been providing reports over the last 3 years for clubs 
              in the MLS, EFL2, EFL1, National League and National league north and south. 
          </div>
          <div class="col-sm-4">
            <h1 class="display-4" style="padding:50px">Opposition and team analysis</h1>
          </div>
      </div>  

      
        <p class="lead">
        </p>
      </div>


    <div class="jumbotron" style="background-color: #F2F2F2; color: #38404B;">
        <div class="row">
            <div class="col-sm-4" style="padding:50px">
              <h1 class="display-4">Data analysis</h1>
            </div>
            <div class="col-sm-6" style="padding:50px">
              Data has become more and more important in todays game. I have previously worked with event data from StatsBomb and Wyscout to provide 
              a deeper look into a teams data and more meaningful insight that can be used to gain a competitive advantage. 
            </div>
        </div>  
        <p class="lead">
        </p>
      </div>

            <div class="jumbotron" style="margin-bottom: 0px;padding-bottom:10px">
      <div class="row">
            <div class="col-sm-4" style="padding:50px;">
              <h1 class="display-4">Contact me</h1>
            </div>
            <div class="col-sm-6" style="padding:50px">
              If you would like to contact me and talk about the analysis I do then please feel free to email me at
              any time and I will get straight back to you.<br />
              Email: <a href="mailto:chrisjr.analyst@gmail.com">the-match-analyst@outlook.com</a>
            </div>
        </div>  

      
        <p class="lead">
        </p>
      </div>



   
    
</div>
</template>
<style scoped>
  .jumbotron {
  background-color: #38404B; 
  border-radius: 25px;
  color: #F2F2F2;
  margin: 40px;
}
</style>

